import { render, staticRenderFns } from "./CandidatesTable.vue?vue&type=template&id=888d1956"
import script from "./CandidatesTable.vue?vue&type=script&lang=js"
export * from "./CandidatesTable.vue?vue&type=script&lang=js"
import style0 from "./CandidatesTable.vue?vue&type=style&index=0&id=888d1956&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataFooter } from 'vuetify/lib/components/VDataIterator';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VCheckbox,VChip,VDataFooter,VDataTable,VDatePicker,VIcon,VListItem,VListItemAction,VListItemContent,VListItemTitle,VMenu,VSelect,VSpacer,VSwitch,VTextField,VToolbar,VTooltip})
