/*eslint-disable*/
import { mapActions, mapGetters } from 'vuex';
import { email, maxLength, required } from 'vuelidate/lib/validators';
import { cloneDeep } from 'lodash';
import { firstnameValidation, lastnameValidation } from '@/common/validationHelpers';
import { EMAIL, FIRST_NAME, LAST_NAME } from '@/common/constants/validations';
import { MESSAGES } from '@/common/constants/errorMessages';

const noCompany = "--"

export default {
  validations: {
    editedItem: {
      last_name: { required, lastnameValidation, maxLength: maxLength(LAST_NAME.MAX_LENGTH) },
      first_name: { required, firstnameValidation, maxLength: maxLength(FIRST_NAME.MAX_LENGTH) },
      email: { required, email, maxLength: maxLength(EMAIL.MAX_LENGTH) },
      managerId: { required },
    },
  },
  data: () => ({
    editedItem: {
      last_name: '',
      first_name: '',
      email: '',
      position: '',
    },
    defaultItem: {
      last_name: '',
      first_name: '',
      email: '',
      position: '',
    },
    curCompany: null,

  }),
  watch: {
    /**
     * @param {boolean | null} newVal
     * @param {boolean | null} oldVal
     * */
    async dialogCreateShow(newVal, oldVal) {
      // По дефолту в positions есть одна запись по этому < 2
      // По возможности надо заморочиться и найти почему метод срабатывает по 2 раза с одинаковыми состояниями new и old
      if (newVal && newVal !== oldVal) {
        if (this.positions?.length < 2 && !this.loadingPositions.loadPositions) {
          await this.loadPositions();
        }
        if (this.managers?.length < 1 && !this.loadingManagers.load) {
          await this.loadManagers();
        }
        const manager = this.managers.find( el => el.id === this.editedItem.managerId)
        this.curCompany = manager && manager.customer ? manager.customer.company  : noCompany
      }
    },
  },
  computed: {
    ...mapGetters({
      loading: 'users/candidates/loading',
      errors: 'users/candidates/errors',
      editedCandidate: 'users/candidates/editedCandidate',
      positions: 'positions/positions/positions',
      loadingPositions: 'positions/positions/loading',
      managers: 'users/managers/managers',
      loadingManagers: 'users/managers/loading',
    }),
    sortedPositions() {
      return this.positions.sort( (a, b) => a.text.toLowerCase().localeCompare(b.text.toLowerCase()) )
    },
    dialogCreateShow: {
      get() {
        return this.$store.getters['users/candidates/dialogCreateShow'];
      },
      set(val) {
        if (!val) {
          this.editedItem = cloneDeep(this.defaultItem);
          this.$v.$reset();
        }
        this.setDialogCreateShow(val);
      },
    },
    dialogDeleteShow: {
      get() {
        return this.$store.getters['users/candidates/dialogDeleteShow'];
      },
      set(val) {
        this.setDialogDeleteShow(val);
      },
    },
    lastnameErrors() {
      const errors = [];
      if (!this.$v.editedItem.last_name.$dirty) return errors;
      if (!this.$v.editedItem.last_name.required) errors.push(MESSAGES.REQUIRED);
      if (!this.$v.editedItem.last_name.lastnameValidation) errors.push(MESSAGES.INCORRECT_LASTNAME);
      if (!this.$v.editedItem.last_name.maxLength) errors.push(MESSAGES.MAX_LENGTH(LAST_NAME.MAX_LENGTH));

      return this.errors.create.find((err) => err[0] === 'last_name')?.[1] ?? errors;
    },
    firstnameErrors() {
      const errors = [];
      if (!this.$v.editedItem.first_name.$dirty) return errors;
      if (!this.$v.editedItem.first_name.required) errors.push(MESSAGES.REQUIRED);
      if (!this.$v.editedItem.first_name.firstnameValidation) errors.push(MESSAGES.INCORRECT_FIRSTNAME);
      if (!this.$v.editedItem.first_name.maxLength) errors.push(MESSAGES.MAX_LENGTH(FIRST_NAME.MAX_LENGTH));

      return this.errors.create.find((err) => err[0] === 'first_name')?.[1] ?? errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.editedItem.email.$dirty) return errors;
      if (!this.$v.editedItem.email.required) errors.push(MESSAGES.REQUIRED);
      if (!this.$v.editedItem.email.email) errors.push(MESSAGES.INCORRECT_EMAIL);
      if (!this.$v.editedItem.email.maxLength) errors.push(MESSAGES.MAX_LENGTH(EMAIL.MAX_LENGTH));

      return this.errors.create.find((err) => err[0] === 'email')?.[1] ?? errors;
    },
    managerErrors() {
      const errors = [];
      if (!this.$v.editedItem.managerId.$dirty) return errors;
      if (!this.$v.editedItem.managerId.required) errors.push(MESSAGES.REQUIRED);

      return this.errors.create.find((err) => err[0] === 'managerId')?.[1] ?? errors;
    },
    filterManagers() {
      const arr = this.curCompany === noCompany
        ? this.managers.slice()
        : this.managers.filter( el => el.customer &&  el.customer.company === this.curCompany );

      arr.sort(function (item1, item2) {
        const a = item1.first_name || item1.last_name ? item1.first_name + ' ' + item1.last_name : 'имя не задано';
        const b = item2.first_name || item2.last_name ? item2.first_name + ' ' + item2.last_name : 'имя не задано';
        return a.toLowerCase().localeCompare(b.toLowerCase());
      });
      return arr;
    },

    companies() {
      return this.managers
        .map(item => item.customer ? item.customer.company : null)
        .filter(item => item)
        .concat([noCompany])
        .sort(function (a, b) {
          return a.toLowerCase().localeCompare(b.toLowerCase());
        });
    }
  },
  methods: {
    ...mapActions({
      clearError: 'users/candidates/clearError',
      setDialogCreateShow: 'users/candidates/setDialogCreateShow',
      setDialogDeleteShow: 'users/candidates/setDialogDeleteShow',
      createCandidate: 'users/candidates/createCandidate',
      deleteCandidates: 'users/candidates/deleteCandidates',
      loadPositions: 'positions/positions/loadPositions',
      loadManagers: 'users/managers/loadManagers',
    }),
    deleteCandidateConfirm() {
      this.deleteCandidates([this.editedCandidate.id]).then(() => {
        this.dialogDeleteShow = false;
      });
    },
    onInput() {
      if (this.errors.create.length) this.clearError();
    },
    createCandidateConfirm() {
      if (this.loading.create) return;
      this.$v.$touch();
      if (!this.editedCandidate && !this.$v.$invalid) {
        const form = {
          ...this.editedItem,
          position: this.editedItem.position,
          manager: this.editedItem.managerId,
        };
        this.createCandidate(form).then(() => {
          this.dialogCreateShow = false;
        });
      }
    },
    changeCompany() {
      const manager = this.filterManagers.find(el => el.id === this.editedItem.managerId)
      if (!manager)
        this.editedItem.managerId = 0
    },
    changeManager()  {
      if (this.editedItem.managerId) {
        const manager = this.managers.find( el => el.id === this.editedItem.managerId)
        this.curCompany = manager && manager.customer ? manager.customer.company  : noCompany
      }
    },
  },
};
