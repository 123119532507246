<template>
  <v-menu offset-y :close-on-content-click="false" ref="menu" v-if="allowedTests.length">
    <template v-slot:activator="{ on, attrs }">
      <v-btn elevation="0" class="font-xs" v-bind="attrs" v-on="on">
        Отправить тест
        <v-icon v-if="attrs['aria-expanded']">mdi-chevron-down</v-icon>
        <v-icon v-else>mdi-chevron-up</v-icon>
      </v-btn>
    </template>
    <v-list :disabled="loading" >
      <v-list :disabled="loading" class="menu-send-test">
        <v-list-item ripple @click="setSelectedTests('ALL')">
          <v-list-item-action class="mr-4">
            <v-icon class="icon-sm" :color="selectedTests.length < allowedTests.length ? '' : 'primary'">{{ iconSelectedTests }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="font-sm">Все</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-for="test in allowedTests" :key="test.id" @click="setSelectedTests(test)">
          <v-list-item-action class="mr-4">
            <v-icon class="icon-sm" :color="isTestSelected(test) ? 'primary' : ''">
              {{isTestSelected(test) ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'}}
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="font-sm">
              {{ test.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <div class="d-flex px-4 py-2">
        <v-btn color="primary" class="mx-auto w-100 btn-disabled-primary"
           :disabled="!selectedTests.length || loading" :loading="loading" small @click.stop="send"
        >
          Отправить
        </v-btn>
      </div>
  </v-list>
  </v-menu>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'CreateTestingSessionButton',
  props: {
    data: {
      type: Object,
      required: true,
    },
    sendTests: {
      type: Function,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    selectedTests: [],
  }),
  computed: {
    ...mapGetters({
      stdTests: 'tests/allowedTests',
      allInstrumentSets: 'testEditor/instrumentSets'
    }),
    allTestsSelected() {
      return this.selectedTests.length === this.allowedTests.length;
    },
    notAllTestsSelected() {
      return this.selectedTests.length > 0 && !this.allTestsSelected;
    },
    iconSelectedTests() {
      if (this.allTestsSelected) return 'mdi-checkbox-marked';
      if (this.notAllTestsSelected) return 'mdi-close-box';
      return 'mdi-checkbox-blank-outline';
    },
    instrumentSets() {
      return this.allInstrumentSets
        .filter(el => el.status === 'published')
        .filter(el => this.$store.getters['tests/isTestCodeAllowed'](el.code))
        .map(el => ({
          title: el.name,
          id: el.code,
          isStd: false,
      }));
    },
    allowedTests() {
      return [
        ...this.stdTests,
        ...this.instrumentSets
      ];
    }

  },
  methods: {
    ...mapActions({
      loadInstrumentSets: 'testEditor/loadInstrumentSets'
    }),
    isTestSelected(test) {
      return this.selectedTests.some((t) => t === test);
    },
    setSelectedTests(val) {
      if (val === 'ALL') {
        if (this.selectedTests.length) {
          this.selectedTests = [];
        } else {
          this.selectedTests = this.allowedTests;
        }
        return;
      }
      if (this.selectedTests.some((t) => t === val)) {
        this.selectedTests = this.selectedTests.filter((t) => t !== val);
      } else {
        this.selectedTests.push(val);
      }
    },

    send() {
      const data = {
        tests: this.selectedTests.map(el => el.id),
        candidate: this.data.id,
      };
      this.sendTests(data).then(() => {
        this.selectedTests = [];
        this.$refs.menu.isActive = false;
        // this.$toast.success('Тест отправлен', '', { position: 'topRight' });
      });
    },
  },
};
</script>

<style >
.menu-send-test {
  max-height: 350px;
  overflow-y: auto;
}
</style>
