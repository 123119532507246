<template>
  <div class="page">

    <div class="row mx-0 my-0 py-0">
      <div class="col-7 d-flex align-center">
          <h1 class="font-lg">Кандидаты</h1>
      </div>
      <div class="col-5  d-flex justify-end" v-if="$store.getters['auth/isAllowed']('add_candidate')">
        <v-btn color="primary" small fab @click="setDialogCreateShow(true)">
          <v-icon small>mdi-account-plus</v-icon>
        </v-btn>
      </div>
    </div>

    <div class="row mx-0 my-0 py-0">
      <div class="col-6 d-flex align-start">
          <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>Остатки тестов: {{ stockBalance + summManagers}}</v-expansion-panel-header>
            <v-expansion-panel-content>
             <div class="mb-4">Мои остатки тестов: {{ stockBalance }}</div>
             <div v-if="$store.getters['auth/isAllowed']('view_stockamount_manager')">Остатки тестов менеджеров: {{ summManagers }}</div>
            </v-expansion-panel-content>
           </v-expansion-panel>
          </v-expansion-panels>
      </div>
      <div class="col-6 d-flex align-start">
          <v-expansion-panels v-if="$store.getters['auth/isAllowed']('view_stockamount_manager')">
          <v-expansion-panel>
            <v-expansion-panel-header>
              Остатки тестов менеджеров:
                <v-progress-circular v-if="loadingManagers.load" :size="20" color="primary" indeterminate/>
                <span v-else class="ml-3">
                    {{summManagers}}
                </span>

            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="managers-list">
                <div v-for="m in sortedManagers" :key="m.id" class="managers-item">
                  <div>{{m.first_name + ' ' + m.last_name}}</div>
                  <div>{{m.stock_balance}}</div>
                </div>
              </div>
            </v-expansion-panel-content>
           </v-expansion-panel>
          </v-expansion-panels>
      </div>
    </div>

    <div class="row d-none _d-md-flex mb-8">
      <div class="col-md-10 col-lg-9">
          <ExpressSendTest />
      </div>

      <div class="col-md-2 col-lg-3 d-flex align-center" v-if="$store.getters['auth/isAllowed']('add_candidate')">
        <v-btn class="ml-auto" color="primary" fab @click="setDialogCreateShow(true)">
          <v-icon>mdi-account-plus</v-icon>
        </v-btn>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <CandidatesTable />
      </div>
    </div>

    <v-speed-dial v-if="selected.length" v-model="candidatesActionsFAB"
                  class="button-fab d-sm-none"
                  bottom right direction="top"
                  transition="slide-y-reverse-transition">
      <template v-slot:activator>
        <v-btn v-model="candidatesActionsFAB" color="#25325F" dark fab>
          <v-icon v-if="candidatesActionsFAB">mdi-close</v-icon>
          <v-icon v-else>mdi-plus</v-icon>
        </v-btn>
      </template>
      <v-btn fab dark small color="primary" class="icon bg-main" outlined>
        <v-icon color="#9BA1B5">mdi-share</v-icon>
      </v-btn>
      <v-btn fab dark small color="primary" class="icon bg-main" outlined
             :loading="loading.loadResults" @click="exportToTable">
        <v-icon color="#9BA1B5">mdi-upload</v-icon>
      </v-btn>
      <v-btn fab dark small color="primary" class="icon bg-main" @click="dialogDeleteShow = true" outlined>
        <v-icon color="#9BA1B5">mdi-delete</v-icon>
      </v-btn>
    </v-speed-dial>

    <DialogDelete v-model="dialogDeleteShow"/>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import CandidatesTable from '@/components/candidates/table/CandidatesTable';
import ExpressSendTest from '@/components/candidates/ExpressSendTest';
import exportToTableMixin from '@/components/candidates/table/exportToTableMixin';
import DialogDelete from '@/components/candidates/DialogDelete';

export default {
  name: 'Candidates',
  mixins: [exportToTableMixin],
  metaInfo: {
    title: 'Кандидаты',
  },
  components: {
    ExpressSendTest,
    CandidatesTable,
    DialogDelete,
  },
  data: () => ({
    candidatesActionsFAB: false,
    dialogDeleteShow: false,
  }),
  computed: {
    ...mapGetters({
      loading: 'users/candidates/loading',
      selected: 'users/candidates/selected',
      stockBalance: 'users/candidates/stockBalance',
      loadingManagers: 'users/managers/loading',
      managers: 'users/managers/managers',
    }),
    summManagers() {
      if (!this.managers || !this.managers.length || !this.$store.getters['auth/isAllowed']('view_stockamount_manager')) {
        return 0;
      }
      return this.managers.reduce((prev, cur) => prev + cur.stock_balance, 0);
    },
    sortedManagers() {
      if (!this.managers || !this.managers.length) {
        return [];
      }
      return this.managers.concat().sort((a, b) => b.stock_balance - a.stock_balance);
    }
  },
  methods: {
    ...mapActions({
      setSelected: 'users/candidates/setSelected',
      setDialogCreateShow: 'users/candidates/setDialogCreateShow',
      loadManagers: 'users/managers/loadManagers',
      loadInstrumentSets: 'testEditor/loadInstrumentSets'

    }),
  },
  created() {
    this.loadManagers();
    this.loadInstrumentSets();
  },
  beforeDestroy() {
    this.setSelected(null);
  },
};
</script>

<style scoped>
.managers-list {
  max-height: 120px;
  overflow-y: scroll;
}

.managers-item {
  display: flex;
  justify-content: space-between;
  margin: 4px 16px;
}
</style>
