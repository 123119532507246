/* eslint-disable */

import CandidateTableModel from '@/common/models/CandidateTableModel';
import * as XLSX from 'xlsx';
import { HEADERS } from '@/common/constants/candidateTableHeaders';
import moment from 'moment';

const NOT_DATA_TEXT = '-';

export default class CandidatesToTable {
  constructor(candidates) {
    this.candidates = candidates;
    this.toTable();
  }

  static getDate(birthdate) {
    return birthdate ? moment(birthdate).format('DD.MM.YYYY') : NOT_DATA_TEXT;
  }

  toTable() {

    const headersRow0 = [
      HEADERS.FIO,
      HEADERS.RELIABILITY,
      'Время прохождения личностного опросника',
      HEADERS.RISK_FACTORS,
      HEADERS.ATTENTION_FACTOR,
      'Компетенции низкоразвитые',
      'Компетенции среднеразвитые',
      'Компетенции высокоразвитые',
    ];

    const arrRowsCount = []
    const rows = this.candidates.map((c) => {
      const candidate = new CandidateTableModel(c);
      let rowsCount = 1;

      let riskFactors = NOT_DATA_TEXT;
      if (candidate.riskFactors) {
        riskFactors = candidate.riskFactors?.map((f) => f.title).join(';\n\r');
        if (rowsCount < candidate.riskFactors.length)
          rowsCount = candidate.riskFactors.length
      }
      let attentionFactor = NOT_DATA_TEXT;
      if (candidate.attentionFactor) {
        attentionFactor = candidate.attentionFactor?.map((f) => f.title).join(';\n\r');
        if (rowsCount < candidate.attentionFactor.length)
          rowsCount = candidate.attentionFactor.length
      }

      let testTime = ''
      if ( candidate.tests?.length) {
        const MMPI = candidate.tests.find(el => el.test_code==='MMPI')
        testTime = MMPI ? MMPI.time : ''
      }

      function getCompetences(comp) {
        if (comp) {
          const arr = comp.competences
          if (arr && arr.length) {
            if (rowsCount < arr.length)
              rowsCount = arr.length
            return arr.map((f) => f.title).join(';\n\r');
          }
        }
        return NOT_DATA_TEXT
      }

      const compLow = getCompetences(candidate.competences?.LOW)
      const compMedium = getCompetences(candidate.competences?.MEDIUM)
      const compHigh = getCompetences(candidate.competences?.HIGHT)

      let str_reliability = ''
      if (candidate.reliability===0) str_reliability = 'Не достоверный'
      if (candidate.reliability===1) str_reliability = 'Сомнительно достоверный'
      if (candidate.reliability===2) str_reliability = 'Достоверный'


      arrRowsCount.push(rowsCount)
      return [
        candidate.fullName || NOT_DATA_TEXT,
        str_reliability,
        testTime,
        riskFactors,
        attentionFactor,
        compLow,
        compMedium,
        compHigh,
      ];
    });

    const data = [
      headersRow0,
      ...rows,
    ];

    const ws = XLSX.utils.aoa_to_sheet(data);

    // if(!ws["!rows"])
    //   ws["!rows"] = [];
    // for(let i=0 ; i<rows.length ; i++)
    //   ws["!rows"][i+1] = {hpx: 20 * arrRowsCount[i]};

    ws['!cols'] = [
      { wch: 30 },   // "Фамилия и Имя",
      { wch: 20 },   // HEADERS.RELIABILITY,
      { wch: 40 },   // 'Время прохождения личностного опросника',
      { wch: 30 },   // HEADERS.RISK_FACTORS,
      { wch: 30 },   // HEADERS.ATTENTION_FACTOR,
      { wch: 30 },   // 'Компетенции низкоразвитые',
      { wch: 30 },   // 'Компетенции среднеразвитые',
      { wch: 30 },   // 'Компетенции высокоразвитые',
    ];

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Кандидаты');

    XLSX.writeFile(wb, 'Кандидаты.xlsx');

    return this.candidates;
  }
}
