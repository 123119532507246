<template>
  <div>
    <v-dialog :value="progress.active" persistent width="500">
      <v-card color="primary" dark class="pa-4">
        <v-card-text style="color:#fff">
          Пожалуйста подождите, идет экспорт...
          <!-- <v-progress-linear indeterminate  color="white" class="mb-0"></v-progress-linear> -->

          <v-progress-linear class="mt-4" style="color:#25325F" color="white" :value="progress.cur/progress.total*100-5 " height="35" >
            {{ progress.cur-progress.blockSize+1 > 0 ? progress.cur-progress.blockSize+1 : 0 }} - {{ progress.cur }} из {{ progress.total }} кандидатов
          </v-progress-linear>

        </v-card-text>
      </v-card>
    </v-dialog>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <div v-on="on" class="d-inline-block">
          <v-btn color="primary" class="mr-1 icon px-0" outlined v-bind="attrs" :disabled="!active"
                :loading="loading.loadResults"
                @click="exportToTable">
            <v-icon :color="active ? 'primary' : '#9BA1B5'">mdi-upload</v-icon>
        </v-btn>
      </div>
      </template>
      <span>Выгрузить в Excel</span>
    </v-tooltip>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <div v-on="on" class="d-inline-block">
          <v-btn color="primary" class="mr-4 icon px-0" outlined v-bind="attrs"
                @click="$store.commit('notifications/setShowPanelColumns', 'ExcelColumns2')">
            <v-icon color="primary">mdi-table-column-remove</v-icon>
        </v-btn>
      </div>
      </template>
      <span>Настроить выгрузку</span>
    </v-tooltip>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <div v-on="on" class="d-inline-block">
          <v-btn color="primary" class="mr-4 icon px-0" outlined v-bind="attrs" :disabled="!active"
                v-if="$store.getters['auth/isAllowed']('download_competence_report')"
                :loading="loading.loadResults"
                @click="exportToTableCompetences">
            <v-icon :color="active ? 'primary' : '#9BA1B5'">mdi-upload-multiple</v-icon>
        </v-btn>
      </div>
      </template>
      <span>Выгрузить в Excel отчет по компетенциям</span>
    </v-tooltip>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <div v-on="on" class="d-inline-block">
          <v-btn v-if="$store.getters['auth/isAllowed']('delete_candidate')" :disabled="!active"
            color="primary" class="px-0 mr-4 icon" outlined v-bind="attrs"
            @click="dialogDeleteShow = true"
          >
            <v-icon :color="active ? 'primary' : '#9BA1B5'">mdi-delete</v-icon>
          </v-btn>
          </div>
      </template>
      <span>Удалить</span>
    </v-tooltip>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <div v-on="on" class="d-inline-block">
          <v-btn v-if="$store.getters['auth/isAllowed']('edit_candidate')"
            color="primary" class="px-0 mr-4 icon" outlined v-bind="attrs" :disabled="!active"
            :loading="loading.active" @click="inactiveCandidates()"
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715
                22 12ZM15.7121 8.78787L17.2121 10.2879L17.4243 10.5L17.2121 10.7121L15.7121 12.2121L15.2879 11.7879L16.2757 10.8H7V10.2H16.2757L15.2879 9.21213L15.7121 8.78787ZM6.78787
                13.7121L8.28787 15.2121L8.71213 14.7879L7.72426 13.8H17V13.2H7.72426L8.71213 12.2121L8.28787 11.7879L6.78787 13.2879L6.57574 13.5L6.78787 13.7121Z" :fill="active ? '#18a0fb' : '#9BA1B5'"/>
            </svg>
          </v-btn>
        </div>
      </template>
      Сделать неактивными
    </v-tooltip>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <div v-on="on" class="d-inline-block">
          <v-btn v-if="$store.getters['auth/isAllowed']('view_testresultscompliencereport')"
            color="primary" class="px-0 mr-4 icon" outlined v-bind="attrs" :disabled="isFilterEmpty" @click="clearFilterAll()">
            <v-icon :color="isFilterEmpty ? '#9BA1B5' : 'primary'">mdi-filter-remove-outline</v-icon>
            <!-- <v-icon :color="isFilterEmpty ? '#9BA1B5' : 'primary'">mdi-share</v-icon> -->
          </v-btn>
          </div>
        </template>
      <span>Сбросить все фильтры</span>
    </v-tooltip>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <div v-on="on" class="d-inline-block">
          <v-btn color="primary" class="px-0 mr-4 icon" outlined v-bind="attrs" :disabled="!active" @click="showCalcComplianceDialog=true">
            <v-icon>mdi-calculator</v-icon>
          </v-btn>
          </div>
        </template>
      <span>Расчитать соответствие</span>
    </v-tooltip>

    <!-- <v-btn color="primary" class="px-0 mr-4 " outlined @click="$store.commit('notifications/setShowPanelColumns', 'ExcelColumnsShort')">
      show short
    </v-btn> -->

    <SelectProfgrams v-model="showCalcComplianceDialog" />
    <DialogDelete v-model="dialogDeleteShow" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CandidatesToTable from '@/common/exportExcel/CandidatesToTable';
import CandidatesToTableCompetences from '@/common/exportExcel/CandidatesToTableCompetences';
import DialogDelete from '@/components/candidates/DialogDelete';
import SelectProfgrams from '@/components/candidates/calc/SelectProfgrams';

export default {
  props: {
    active: Boolean
  },
  name: 'CandidatesTableActions',
  components: {
    DialogDelete, SelectProfgrams
  },
  data: () => ({
    dialogDeleteShow: false,
    showCalcComplianceDialog: false,
  }),
  computed: {
    ...mapGetters({
      loading: 'users/candidates/loading',
      progress: 'users/candidates/progress',
      isFilterEmpty: 'users/candidates/isFilterEmpty',
    }),
  },
  methods: {
    ...mapActions({
      inactiveCandidates: 'users/candidates/inactiveCandidates',
      clearFilterAll: 'users/candidates/clearFilterAll',
      loadCandidatesResultsProgress: 'users/candidates/loadCandidatesResultsProgress',
    }),
    exportToTable() {
      this.loadCandidatesResultsProgress().then((resp) => {
        // eslint-disable-next-line no-new
        new CandidatesToTable(resp);
      });
    },
    exportToTableCompetences() {
      this.loadCandidatesResultsProgress().then((resp) => {
        // eslint-disable-next-line no-new
        new CandidatesToTableCompetences(resp);
      });
    },

  }

};
</script>
